import React from "react";
import {
  Segment,
  Item,
  Header,
  Icon,
  Divider,
  Grid,
  SemanticICONS,
} from "semantic-ui-react";

type ExpEntry = {
  key: number;
  header: string;
  description: string;
  logoName: SemanticICONS;
  period: string;
};

export const workPositions: ExpEntry[] = [
  {
    key: 1,
    header: "NodeJs Software Engineer at Telesoftas",
    description: "AWS CloudFormation, React development using Typescript",
    logoName: "node",
    period: "Started on - 2021-04",
  },
  {
    key: 2,
    header: "Broadcasting Engineer",
    description: `Real-time live feed management in the first HD television in
      Lithuania.`,
    logoName: "podcast",
    period: "Started on - 2017-04",
  },
  {
    key: 3,
    header: "Team leader of Innovation IoT Teltonika Academy",
    description:
      "Building SAS Teltonika IoT Academy from ground up. Recrutmenting young talents. Coordinating current interns work",
    logoName: "users",
    period: "2019-03 - 2021-04",
  },
  {
    key: 4,
    header: "Graphic Design Engineer",
    description: `Everyday responsibilities includes creating and editing
    animations, documentations, visuals using modern editing tools
    including Adobe package. \n Providing internal and
    external technical support of fleet management systems.`,
    logoName: "paint brush",
    period: "2019-01 - 2019-03",
  },
  {
    key: 5,
    header: "Technical Support Engineer",
    description: `Providing internal and external dualingual technical support of fleet
      management systems.`,
    logoName: "wrench",
    period: "2018-07 - 2019-03",
  },
  {
    key: 6,
    header: "IT Administrator",
    description: `Providing local IT technical support for 40 users and over 1000
      users remotely.`,
    logoName: "computer",
    period: "2017-06 - 2018-06",
  },
];

export const Experience = () => {
  return (
    <>
      <Header inverted textAlign="center" style={{ marginTop: "10px" }}>
        Experience
      </Header>
      <Segment>
        <Item.Group fluid vertical centered doubling stackable>
          {workPositions.map((item, i) => {
            return (
              <div key={item.key}>
                <Item>
                  <Grid columns={2}>
                    <Grid.Column width={1} floated="left">
                      <Icon size="huge" name={item.logoName} />
                    </Grid.Column>
                    <Grid.Column width={14} floated="left">
                      <Item.Content>
                        <Item.Header>
                          <h4>{item.header}</h4>
                        </Item.Header>
                        <Item.Description>
                          <p>{item.description}</p>
                        </Item.Description>
                      </Item.Content>
                      <span
                        style={{
                          color: "grey",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {item.period}
                      </span>
                    </Grid.Column>
                  </Grid>
                </Item>
                <Divider />
              </div>
            );
          })}
        </Item.Group>
      </Segment>
    </>
  );
};
