import React from "react";
import { createMedia } from "@artsy/fresnel";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PDFGeneration } from "./forPDF/PDFGeneration";
import { createBrowserHistory } from "history";
import { Content } from "./Content";

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});
const mediaStyles = AppMedia.createMediaStyle();
const { MediaContextProvider } = AppMedia;

const App = () => {
  const history = createBrowserHistory();
  history.listen((update) => console.log(update.location.pathname));

  return (
    <div>
      <style>{mediaStyles}</style>
      <MediaContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/PDFGeneration" element={<PDFGeneration />} />
            <Route path="*" element={<Content />}></Route>
          </Routes>
        </BrowserRouter>
      </MediaContextProvider>
    </div>
  );
};

export default App;
