import React from "react";
import { Menu, MenuItemProps } from "semantic-ui-react";
import { MyMenuItem } from "./myMenuItem";

interface MyProps {
  changeContent(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: MenuItemProps
  ): void;
}

export const MainMenu = ({ changeContent }: MyProps) => {
  const downloadCV = () => {
    const childWin = window.open("/Simonas_Berankis.pdf", "_blank");
    childWin?.location.replace("/Simonas_Berankis.pdf");
  };

  return (
    <Menu
      inverted
      vertical
      secondary
      style={{ maxWidth: "55px", minWidth: "30px", minHeight: "60px" }}
    >
      <MyMenuItem
        iconName="user"
        title="AboutPage"
        handleItemClick={changeContent}
      />
      <MyMenuItem
        iconName="call"
        title="Contacts"
        handleItemClick={changeContent}
      />
      <MyMenuItem
        iconName="suitcase"
        title="Experience"
        handleItemClick={changeContent}
      />
      {/* <MyMenuItem
        iconName="code"
        title="Portfolio"
        handleItemClick={changeContent}
      /> */}
      <MyMenuItem
        iconName="download"
        title="DownloadCV"
        handleItemClick={downloadCV}
      />
    </Menu>
  );
};
