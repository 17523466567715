import React from "react";
import { Icon, Grid, GridColumn } from "semantic-ui-react";
export interface IProps {
  hrefTextColor?: string;
}
export const ContactsInfo = ({ hrefTextColor }: IProps) => {
  return (
    <Grid columns={2} divided inverted>
      <Grid.Row divided>
        <Grid.Column width="8" textAlign="right">
          <p>
            <b>
              <Icon inverted name="phone" /> Phone number
            </b>
          </p>
          <p>
            <b>
              <Icon inverted name="mail" /> e-mail
            </b>
          </p>
          <p>
            <b>
              <Icon inverted name="world" /> Web Page
            </b>
          </p>
        </Grid.Column>
        <GridColumn width="8" textAlign="left">
          <p>
            <b>+370 6 905 1022</b>
          </p>
          <p>
            <b>
              <a
                style={{
                  color: hrefTextColor ?? "white",
                }}
                href="mailto:s.berankis@gmail.com"
              >
                s.berankis@gmail.com
              </a>
            </b>
          </p>
          <p>
            <a
              style={{
                color: hrefTextColor ?? "white",
              }}
              href="https://simonas.berankis.com"
            >
              <b>simonas.berankis.com</b>
            </a>
          </p>
        </GridColumn>
      </Grid.Row>
    </Grid>
  );
};
