import React, { useState } from "react";
import {
  Container,
  Image,
  Grid,
  Transition,
  Placeholder,
} from "semantic-ui-react";
import Typist from "react-typist";
import { Quotes } from "../Quotes";

const HeaderAboutText = () => {
  return (
    <div>
      <h2> Hello, I am Simonas Berankis!</h2>
    </div>
  );
};

export const AboutPageText = (): React.ReactElement => {
  return (
    <div>
      <p>
        <br />
        I'm a Software Engineer at Telesoftas. I’m passionate about science,
        scepticism and critical thinking. I love learning new things and eager
        to share ideas. <br />
        <br />
        I would describe myself as a well disciplined person with a strict
        routine. ⏱<br />
        <br />
        📚 - I love reading, mostly SciComm.
        <br />
        🥾- Long hikes always refreshes me, 30 kilometres is not a challenge.
        <br />
        🎲 - I enjoy playing advanced board games.
        <br />
        🏕- Lake 🏊, forest 🌳, campfire 🪵 and sleepless night 🌔 playing
        charades with good company defines perfect vacation.
        <br />
      </p>
    </div>
  );
};

export const MainText = {
  header: <HeaderAboutText />,
  body: <AboutPageText />,
};

export const AboutPage = (): React.ReactElement => {
  const [contentVisible, setContentVisible] = useState(false);
  const [quoteVisible, setQuoteVisible] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(true);

  return (
    <Grid style={{ minHeight: "55vh" }}>
      <Grid.Row columns={2}>
        <Grid.Column verticalAlign="middle" width="5">
          {loadingProfile && (
            <Placeholder inverted>
              <Placeholder.Image square />
            </Placeholder>
          )}
          <Image
            onLoad={() => setLoadingProfile(false)}
            rounded
            style={
              loadingProfile
                ? { display: "none" }
                : { filter: "drop-shadow(2px 2px 12px #FFFF)" }
            }
            src="./Profile_clean_background.png"
            fluid
          />
        </Grid.Column>
        <Grid.Column verticalAlign="top" width="10" floated="left">
          <Container
            textAlign="left"
            style={{
              textAlign: "left",
              color: "white",
              marginTop: "8vh",
            }}
          >
            <Typist
              avgTypingDelay={55}
              cursor={{ show: false }}
              onTypingDone={() => setContentVisible(true)}
            >
              <Typist.Delay ms={500} />
              <h1>
                Hi, <Typist.Delay ms={800} />I am Simonas!
              </h1>
            </Typist>
            <Transition
              visible={contentVisible}
              animation="fade"
              duration={1000}
              onComplete={() => setQuoteVisible(true)}
            >
              <div>
                <AboutPageText />
              </div>
            </Transition>
            <Transition visible={quoteVisible} animation="fade" duration={1000}>
              <div>
                <Quotes />
              </div>
            </Transition>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
