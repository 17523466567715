import { createMedia } from "@artsy/fresnel";
import React from "react";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  MenuItemProps,
  Segment,
  Transition,
} from "semantic-ui-react";
import { MyBackground } from "./background";
import { FootNote } from "./FootNote";
import { MainMenu } from "./menu/MainMenu";
import { MobileContent } from "./MobileContent";
import { AboutPage } from "./pages/AboutPage";
import { Contacts } from "./pages/Contacts";
import { Experience } from "./pages/Experience";
import { Portfolio } from "./pages/Portfolio";

enum MenuString {
  ABOUT_PAGE = "/AboutPage",
  CONTACTS = "/Contacts",
  EXPERIENCE = "/Experience",
  PORTFOLIO = "/Portfolio",
}

interface RouteMap {
  route: string;
  content: React.ReactElement;
}

const renderArray: Array<RouteMap> = [
  { route: "/", content: <AboutPage /> },
  { route: MenuString.ABOUT_PAGE, content: <AboutPage /> },
  { route: MenuString.CONTACTS, content: <Contacts /> },
  { route: MenuString.EXPERIENCE, content: <Experience /> },
  { route: MenuString.PORTFOLIO, content: <Portfolio /> },
];

export const Content = () => {
  const navigate = useNavigate();

  const AppMedia = createMedia({
    breakpoints: {
      mobile: 320,
      tablet: 768,
      computer: 992,
      largeScreen: 1200,
      widescreen: 1920,
    },
  });

  const changeContent = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: MenuItemProps
  ) => {
    navigate("/" + data.name);
  };

  const { Media } = AppMedia;
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 3,
        pointerEvents: "none",
      }}
    >
      <MyBackground />
      <Grid
        columns="2"
        className="mainApp"
        as={Media}
        greaterThanOrEqual="computer"
        d
      >
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={1} floated="right">
            <Container
              style={{
                pointerEvents: "all",
              }}
            >
              <MainMenu changeContent={changeContent} />
            </Container>
          </Grid.Column>
          <Grid.Column width={13} floated="left">
            <Container
              style={{
                pointerEvents: "all",
              }}
              fluid
            >
              <Segment
                inverted
                raised
                style={{
                  minHeight: "48vh",
                  boxShadow: "4px 4px 13px 1px #242424",
                }}
              >
                <Routes>
                  {renderArray.map((item) => (
                    <Route
                      key={item.route}
                      path={item.route}
                      element={
                        <div>
                          <Transition
                            mountOnShow
                            transitionOnMount
                            unmountOnHide
                            animation="fade"
                            duration={{ hide: 1000, show: 1000 }}
                          >
                            <div>{item.content}</div>
                          </Transition>
                        </div>
                      }
                    ></Route>
                  ))}
                </Routes>
              </Segment>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid className="mainApp" as={Media} lessThan="computer">
        <div
          style={{
            zIndex: 2,
            position: "absolute",
          }}
        >
          <MobileContent />
        </div>
      </Grid>
      <Container
        as={Media}
        greaterThanOrEqual="computer"
        className="ignoreHover"
        fluid
        style={{
          zIndex: 2,
          position: "absolute",
          height: "8%",
          bottom: "5px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            pointerEvents: "all",
            position: "fixed",
            left: "50%",
            bottom: "30px",
          }}
        >
          <FootNote />
        </div>
      </Container>
      <Outlet />
    </div>
  );
};
