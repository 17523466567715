import React from "react";
import { Icon } from "semantic-ui-react";

export const FootNote = () => {
  // onClick={()=>{window.open("https://www.facebook.com/simonas.berankis/","_blank")}}
  return (
    <>
      <Icon
        onClick={() => {
          window.open("https://www.facebook.com/simonas.berankis/");
        }}
        link
        inverted
        circular
        name="facebook"
      ></Icon>
      <Icon
        onClick={() => {
          window.open("https://gitlab.com/Berankis");
        }}
        link
        inverted
        circular
        name="gitlab"
      ></Icon>
      <Icon
        onClick={() => {
          window.open(
            "https://www.linkedin.com/in/simonas-berankis-533095131/"
          );
        }}
        link
        inverted
        circular
        name="linkedin"
      ></Icon>
    </>
  );
};
