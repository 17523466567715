import React from "react";
import {
  Segment,
  Item,
  Image,
  Grid,
  Icon,
  Divider,
  Header,
} from "semantic-ui-react";
import { MainText } from "../pages/AboutPage";
import { workPositions } from "../pages/Experience";
import { IProps, ContactsInfo } from "../pages/ContactsInfo";
import Skills from "./Skills";

const fontColor: IProps = {
  hrefTextColor: "black",
};

export const PDFGeneration = () => {
  return (
    <div
      style={{
        width: "21cm",
        maxWidth: "21cm",
        maxHeight: "29.7cm",
        height: "29.7cm",
      }}
    >
      <Segment
        inverted
        raised
        style={{ boxShadow: "4px 4px 13px 1px #242424" }}
      >
        <Grid>
          <Grid.Column width={3}>
            <Image rounded src="./DSC_0067_CROPED.png" size="small"></Image>
          </Grid.Column>
          <Grid.Column width={12}>
            {MainText.header}
            {MainText.body}
          </Grid.Column>
        </Grid>
      </Segment>
      <div style={{ marginTop: "3vh" }}>
        <Segment
          inverted
          raised
          style={{ boxShadow: "4px 4px 13px 1px #242424" }}
        >
          <Header inverted textAlign="center">
            Contacts
          </Header>
          <Segment>
            <Grid
              centered
              style={{ marginTop: "5px", marginBottom: "5px", fontSize: "70%" }}
              divided
              columns={2}
            >
              <Grid.Column floated="right" width={7}>
                <ContactsInfo {...fontColor} />
              </Grid.Column>
              <Grid.Column floated="left" width={7}>
                <p>
                  <b>
                    <a href="https://www.facebook.com/simonas.berankis/">
                      {" "}
                      <Icon color="black" name="facebook"></Icon>
                    </a>{" "}
                    <a
                      style={{ color: "black" }}
                      href="http://www.facebook.com/simonas.berankis"
                    >
                      http://www.facebook.com/simonas.berankis
                    </a>
                  </b>
                </p>
                <p>
                  <b>
                    <a href="https://gitlab.com/Berankis">
                      {" "}
                      <Icon color="black" name="gitlab"></Icon>
                    </a>{" "}
                    <a
                      style={{ color: "black" }}
                      href="https://gitlab.com/Berankis"
                    >
                      https://gitlab.com/Berankis
                    </a>
                  </b>
                </p>
                <p>
                  <b>
                    <a href="https://www.linkedin.com/in/simonas-berankis-533095131/">
                      {" "}
                      <Icon color="black" name="linkedin"></Icon>
                    </a>{" "}
                    <a
                      style={{ color: "black" }}
                      href="https://www.linkedin.com/in/simonas-berankis-533095131"
                    >
                      https://www.linkedin.com/in/simonas-berankis-533095131
                    </a>
                  </b>
                </p>
              </Grid.Column>
            </Grid>
          </Segment>
        </Segment>
        <Segment
          inverted
          raised
          style={{ boxShadow: "4px 4px 13px 1px #242424" }}
        >
          <Header textAlign="center">Experience</Header>
          <Segment>
            <Grid style={{ marginTop: "3px" }}>
              <Item.Group fluid vertical="true" centered doubling stackable>
                {workPositions.map((item, i) => {
                  return (
                    <div key={item.key}>
                      <Item>
                        <Grid columns={2}>
                          <Grid.Row>
                            <Grid.Column width={12}>
                              <Item.Content>
                                <Item.Header>
                                  <h4 style={{ fontSize: "70%" }}>
                                    {item.header}
                                  </h4>
                                </Item.Header>
                                <Item.Description>
                                  <div style={{ fontSize: "70%" }}>
                                    {item.description}
                                  </div>
                                </Item.Description>
                              </Item.Content>
                            </Grid.Column>
                            <Grid.Column width={4} floated="right">
                              <div style={{ color: "grey", fontSize: "70%" }}>
                                {item.period}
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Item>
                      <Divider />
                    </div>
                  );
                })}
              </Item.Group>
            </Grid>
          </Segment>
        </Segment>
        <Segment
          inverted={true}
          raised
          style={{ boxShadow: "4px 4px 13px 1px #242424" }}
        >
          <Segment>
            <Grid width={4}>
              <Grid.Column width={4}>
                <Skills name="node" level={4}></Skills>
              </Grid.Column>
              <Grid.Column width={4}>
                <Skills name="react" level={3}></Skills>
              </Grid.Column>
              <Grid.Column width={4}>
                <Skills name="aws" level={3}></Skills>
              </Grid.Column>
              <Grid.Column width={4}>
                <Skills name="linux" level={4}></Skills>
              </Grid.Column>
            </Grid>
          </Segment>
        </Segment>
      </div>
    </div>
  );
};
