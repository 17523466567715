import React from "react";
import {
  Segment,
  Image,
  Button,
  Header,
  Icon,
  Grid,
  Divider,
} from "semantic-ui-react";
import { FootNote } from "./FootNote";

export const MobileContent = () => {
  return (
    <Segment
      textAlign="center"
      inverted
      raised
      style={{
        boxShadow: "4px 4px 13px 1px #242424",
        width: "320px",
        height: "600px",
        marginLeft: "-160px",
        marginTop: "-300px",
        top: "50%",
        left: "50%",
        position: "fixed",
      }}
    >
      <Grid>
        <Grid.Row centered>
          <Image
            style={{ marginTop: "20px" }}
            circular
            src="./DSC_0067_CROPED.png"
            size="small"
          />
        </Grid.Row>
        <Grid.Row centered>
          <Header inverted>Simonas Berankis</Header>
        </Grid.Row>
        <Grid.Row centered>
          <Button.Group inverted>
            <Button
              inverted
              onClick={() => {
                window.open("tel:+37069051022");
              }}
            >
              {" "}
              <Icon name="call" /> Call
            </Button>
            <Button.Or inverted="true" />
            <Button
              inverted
              onClick={() => {
                window.open("mailto:s.berankis@gmail.com");
              }}
            >
              {" "}
              <Icon name="send" />
              Email
            </Button>
          </Button.Group>
          <Grid inverted style={{ marginTop: "40px" }}>
            <Grid.Row style={{ marginLeft: "40px" }}>
              <Grid.Column width={2}>
                <Icon size="large" name="call" />
              </Grid.Column>
              <Grid.Column width={10} textAlign="left">
                <h3>+370 690 51022</h3>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row style={{ marginLeft: "40px" }}>
              <Grid.Column width={2}>
                <Icon size="large" name="mail" />
              </Grid.Column>
              <Grid.Column width={10} textAlign="left">
                <h3>s.berankis@gmail.com</h3>
              </Grid.Column>
            </Grid.Row>
            <Divider />

            <Grid.Row style={{ marginLeft: "40px" }}>
              <Grid.Column width={2}>
                <Icon size="large" name="suitcase" />
              </Grid.Column>
              <Grid.Column width={10} textAlign="left">
                <Image
                  style={{ marginTop: "-5px" }}
                  src="./Telesoftas_logo_Outline-White_rgb.png"
                  size="small"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div style={{ marginTop: "22px" }}>
            <FootNote />
          </div>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
