import React from "react";

let quotesPool = [
  {
    text:
      "We live in a society exquisitely dependent on science and technology, in which hardly anyone knows anything about science and technology.",
    author: "Carl Sagan",
  },
  {
    text:
      "The nitrogen in our DNA, the calcium in our teeth, the iron in our blood, the carbon in our apple pies were made in the interiors of collapsing stars. We are made of starstuff.",
    author: "Carl Sagan",
  },
  {
    text:
      "Ignorance is a low-energy state. It takes constant vigilance and work to climb out of it.",
    author: "Steven Novella",
  },
  {
    text:
    "Extraordinary claims require extraordinary evidence.",
    author: "Carl Sagan",
  },
  {
    text:
    "It is pretty well established that there is an overconfidence effect. ... You don't have the competence to assess your own competence. ... you need competence in order to assess your own competence. ... Everyone has the Dunning-Kruger effect. ",
    author: "Steven Novella",
  },
];

let randomizeQuote = Math.floor(Math.random() * quotesPool.length);

export const Quotes=() =>{
  return (
    <div>
      <p style={{ marginTop: "8vh" }}>
        <i>"{quotesPool[randomizeQuote].text}"</i>
      </p>
  <h4 style={{ textAlign: "right" }}><i style={{ marginRight: '.5rem' }} >―</i> <i>{`${quotesPool[randomizeQuote].author}`}</i></h4>
    </div>
  );
}
