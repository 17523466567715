import React from "react";
import { Menu, Icon, MenuItemProps } from "semantic-ui-react";

export interface MyMenuItemProps {
  title?: string;
  iconName?: any;
  activeItem?: string;
  handleItemClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: MenuItemProps
  ) => void;
}

export const MyMenuItem = ({
  title,
  iconName,
  activeItem,
  handleItemClick,
}: MyMenuItemProps) => {
  return (
    <Menu.Item
      name={title}
      active={activeItem === title}
      onClick={handleItemClick}
      style={{ marginBottom: "3px", height: "5vh", width: "5vh" }}
    >
      <Icon color="black" size="large" name={iconName}></Icon>
    </Menu.Item>
  );
};
