import React from "react";
import { Header, Card, Icon } from "semantic-ui-react";

export const Portfolio = () => {
  const cardContentStyle = {
    height: "100px",
  };
  const cardHeaderStyle = {
    height: "100px",
  };

  return (
    <>
      <Header inverted={true} textAlign="center" style={{ marginTop: "10px" }}>
        Portfolio
      </Header>
      <Card.Group centered itemsPerRow={4}>
        <Card color="grey">
          <Header style={cardHeaderStyle} as="h1" icon textAlign="center">
            IOCube
            <Icon name="cube" size="huge" color="black" />
          </Header>
          <Card.Content
            style={cardContentStyle}
            href="https://gitlab.com/Berankis/iocubeuitdd"
          >
            <Card.Header>IOCube-UI</Card.Header>
            <Card.Meta>Created in 2020</Card.Meta>
            <Card.Description>
              IoT academy project, for education purpose
            </Card.Description>
          </Card.Content>
          <Card.Meta textAlign="center" style={{ margin: "10px" }}>
            <a
              href="https://gitlab.com/Berankis/iocubeuitdd"
              style={{ color: "#03a5fc" }}
            >
              Source
            </a>
          </Card.Meta>
        </Card>
        <Card centered color="grey">
          <Header style={cardHeaderStyle} as="h1" icon textAlign="center">
            CTF
            <Icon name="flag" size="huge" color="black" />
          </Header>
          <Card.Content
            style={cardContentStyle}
            href="http://simonas.berankis.com:8413"
          >
            <Card.Header>CTF</Card.Header>
            <Card.Meta>Created in 2021</Card.Meta>
            <Card.Description>
              Project dedicated for Teltonika CTF competition
            </Card.Description>
          </Card.Content>
          <Card.Meta textAlign="center" style={{ margin: "10px" }}>
            <a
              href="https://gitlab.com/iot-academy/isp/ctf"
              style={{ color: "#03a5fc" }}
            >
              View source
            </a>
          </Card.Meta>
        </Card>
        <Card centered color="grey">
          <Header style={cardHeaderStyle} as="h1" icon textAlign="center">
            Training Task
            <Icon name="flask" size="huge" color="black" />
          </Header>
          <Card.Content
            style={cardContentStyle}
            href="https://gitlab.com/Berankis/eval_test_ps"
          >
            <Card.Header>Training Task</Card.Header>
            <Card.Meta>Created in 2021</Card.Meta>
            <Card.Description>
              Project dedicated for self sraining and evaluation
            </Card.Description>
          </Card.Content>
          <Card.Meta textAlign="center" style={{ margin: "10px" }}>
            <a
              href="https://gitlab.com/Berankis/eval_test_ps"
              style={{ color: "#03a5fc" }}
            >
              View source
            </a>
          </Card.Meta>
        </Card>
      </Card.Group>
    </>
  );
};
