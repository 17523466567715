import React from "react";
import { Grid, Icon, Header } from "semantic-ui-react";

type IconList = "node" | "react" | "handshake outline" | "linux" | "aws";
export default function Skills(
  props: Readonly<{ name: IconList; level: number }>
) {
  let circles = [];
  for (let index = 0; index < props.level; index++) {
    circles.push(<Icon name="circle" size="tiny"></Icon>);
  }
  for (let index = 0; index < 5 - props.level; index++) {
    circles.push(<Icon name="circle outline" size="tiny"></Icon>);
  }
  return (
    <div>
      <Grid>
        <Grid.Row centered>
          <Header as="h1" icon>
            <Icon name={props.name} />
          </Header>
        </Grid.Row>
        <Grid.Row centered style={{ marginTop: "-45px" }}>
          {circles.map((circle) => {
            return circle;
          })}
        </Grid.Row>
      </Grid>
    </div>
  );
}
