import React, { useState } from "react";
import {
  Grid,
  Header,
  Form,
  Message,
  Container,
  Divider,
} from "semantic-ui-react";
import { ContactsInfo } from "./ContactsInfo";

export const Contacts = () => {
  const [message, setMessage] = useState({
    hidden: true,
    content: "",
    warning: false,
  });
  const [values, setValues] = useState({
    name: "",
    company: "",
    email: "",
    message: "",
  });

  const handleOnChange = (event: any) => {
    event.persist();
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  let submitForm = async () => {
    const options = {
      body: JSON.stringify(values),
      headers: { Accept: "application/json" },
      method: "POST",
    };
    let response = await fetch("https://formspree.io/mvowdqyo", options);
    let result = await response.json();
    console.log(result);
    if (response.status === 200) {
      setValues({ name: "", company: "", email: "", message: "" });
      setMessage({
        hidden: false,
        content: "Thank you! I will write You back.",
        warning: false,
      });
      setTimeout(
        () => setMessage({ hidden: true, content: "", warning: false }),
        5000
      );
    } else {
      setMessage({
        hidden: false,
        content: "Something went wrong, please use contacts above",
        warning: true,
      });
      setTimeout(
        () => setMessage({ hidden: true, content: "", warning: false }),
        5000
      );
    }
  };

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width="8">
            <Header inverted textAlign="center" style={{ marginTop: "10px" }}>
              Contacts
            </Header>
            <ContactsInfo />
            <Container style={{ marginTop: "40px" }} textAlign="center">
              <Message
                color="black"
                warning={message.warning}
                positive={!message.warning}
                hidden={message.hidden}
              >
                {message.content}
              </Message>
            </Container>
          </Grid.Column>
          <Grid.Column width="7" style={{ marginLeft: "20px" }}>
            <Header inverted textAlign="center" style={{ marginTop: "10px" }}>
              Write me
            </Header>
            <Grid.Row divided>
              <Form inverted onSubmit={submitForm}>
                <Form.Input
                  fluid
                  required
                  name="name"
                  label="Name"
                  value={values.name}
                  placeholder="Name"
                  onChange={handleOnChange}
                />
                <Form.Input
                  fluid
                  required
                  value={values.company}
                  name="company"
                  label="Company"
                  placeholder="Company"
                  onChange={handleOnChange}
                />
                <Form.Input
                  fluid
                  value={values.email}
                  required
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="name@company.com"
                  onChange={handleOnChange}
                />
                <Form.TextArea
                  fluid="true"
                  name="message"
                  value={values.message}
                  required
                  type="text"
                  label="Message"
                  placeholder="Your message to me"
                  onChange={handleOnChange}
                />
                {/* <ReCAPTCHA theme="dark" badge="bottomright"
                    sitekey="6LeJ0zkaAAAAAO6KBMFFr8XrhxbLAE5lIVAcbxzF"
                    onChange={onCapchaChange}
                  /> */}
                <Form.Button basic inverted floated="right">
                  Send
                </Form.Button>
              </Form>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider inverted vertical>
        OR
      </Divider>
    </>
  );
};
